@import '../../../styles/theme/index.less';
@import '../../../styles/theme/vars.less';

.note {
	display: grid;
	grid-template-columns: calc(49vw - 10px) 10px calc(49vw - 10px);

	.details {
		padding-left: 8vw;

		.form {
			.shadow-box;
			min-height: 85vh;
			padding: 25px;
			width: 100%;

			.datePicker,
			.inputField {
				width: 260px !important;
			}

			.shortDatePicker {
				width: 125px;
			}

			.actions {
				margin: 10px 0px;

				.save {
					width: 250px;

					.secondaryButton;
				}
			}
		}
	}


.preview {
	margin-left: 15px;
	.viewer {
		.shadow-box;
		padding-left: 8vw;
		padding: 25px;
		min-height: 85vh;

		iframe {
			height: calc(85vh - 35px);
		}
	}
}

.divider {
	height: 85vh;
}
}
.container {
	max-width: 95%;
	text-align: center;
  }
  
  .controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	margin-bottom: 10px;
  }
  
  .downloadButton {
	margin-left: 10px;
  }
  
  .slider {
	width: 100%;
  }
  
  .imageContainer {
	width: 100%;
	height: calc(85vh - 35px);
	overflow: auto;
	display: flex;
	justify-content: center;
	position: relative;
	border: 1px solid #ccc;
  }
  
  .imageWrapper {
	transform-origin: center;
	display: inline-block;
	white-space: nowrap;
  }
  
  .image {
	height: auto;
	display: block;
  }